import '../styling/main.css';
import { ApolloInjector } from './ApolloInjector';

import 'font-awesome/css/font-awesome.min.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AuthManager from './auth/AuthManager';
import RoutesInternal from './Routes';
import WindowContext from '../context/WindowContext';
import '@atrocit/scl/build/index.css';
import { HotkeysProvider } from 'react-hotkeys-hook';

function App() {
	return <ApolloInjector>
		<AuthManager>
			<DndProvider backend={HTML5Backend}>
				<HotkeysProvider>
					<WindowContext.Provider value={window}>
						<RoutesInternal />
					</WindowContext.Provider>
				</HotkeysProvider>
			</DndProvider>
		</AuthManager>
	</ApolloInjector>;
}

export default App;
