import _ from 'lodash';
import { generateLowerTiers, generateRows, generateUpperTiers, toCellCode } from './BigBayTemplate';
import React from 'react';

export default function MiniBayTemplate({ containerDiggingMap, colorMode, voyageData, showSwapSuggestions, cellsByCode, shifters, highlightCell, inOutBound, hasTemplate, templateCells, bay, bayNumber, setStatusMsg = () => {}, selectedBay, onSelectBay, polFilter, blockedCells, combinedBays, editingMode }) {
	const selected = bayNumber == selectedBay;
	const { cells } = bay;

	const byRow = _.groupBy(cells, 'row');

	return <div className={'bay' + (selected ? ' bay-selected' : '')} onClick={() => onSelectBay()}>
		<div className="bay-upper-deck">
			<Rows
				colorMode={colorMode}
				voyageData={voyageData}
				showSwapSuggestions={showSwapSuggestions}
				cellsByCode={cellsByCode}
				combinedBays={combinedBays}
				shifters={shifters}
				highlightCell={highlightCell}
				hasTemplate={hasTemplate}
				templateCells={templateCells}
				bay={bay}
				bayNumber={bayNumber}
				lowerDeck={false}
				byRow={byRow}
				setStatusMsg={setStatusMsg}
				inOutBound={inOutBound}
				polFilter={polFilter}
				blockedCells={blockedCells}
				containerDiggingMap={containerDiggingMap}
				editingMode ={editingMode}/>
		</div>
		<div className="bay-lower-deck">
			<Rows
				colorMode={colorMode}
				voyageData={voyageData}
				showSwapSuggestions={showSwapSuggestions}
				cellsByCode={cellsByCode}
				combinedBays={combinedBays}
				shifters={shifters}
				highlightCell={highlightCell}
				hasTemplate={hasTemplate}
				templateCells={templateCells}
				bay={bay}
				bayNumber={bayNumber}
				lowerDeck={true}
				byRow={byRow}
				setStatusMsg={setStatusMsg}
				inOutBound={inOutBound}
				polFilter={polFilter}
				blockedCells={blockedCells}
				containerDiggingMap={containerDiggingMap}
				editingMode ={editingMode} />
		</div>
	</div>;
}

function Rows({ containerDiggingMap, colorMode, voyageData, showSwapSuggestions, cellsByCode, combinedBays, shifters = new Set(), highlightCell, inOutBound, hasTemplate, templateCells, bay, bayNumber, lowerDeck = false, byRow, setStatusMsg, polFilter, blockedCells, editingMode }) {
	return <>
		{generateRows(hasTemplate, bay, editingMode).map((row, rIdx) => {
			const byTier = _.keyBy(byRow[row], 'tier');
			return <div key={row} className="row" style={{ display: 'flex', flexDirection: 'column-reverse' }}>
				{(lowerDeck ? generateLowerTiers(hasTemplate, bay, false) : generateUpperTiers(hasTemplate, bay, false)).map((tier, tIdx) => <Cell
					cell={byTier[tier]}
					bayNumber={bayNumber}
					row={row}
					tier={tier}
					hasTemplate={hasTemplate}
					templateCells={templateCells}
					highlightCell={highlightCell}
					shifters={shifters}
					polFilter={polFilter}
					inOutBound={inOutBound}
					editingMode={editingMode}
					cellsByCode={cellsByCode}
					blockedCells={blockedCells}
					combinedBays={combinedBays}
					showSwapSuggestions={showSwapSuggestions}
					voyageData={voyageData}
					colorMode={colorMode}
					containerDiggingMap={containerDiggingMap} />)}
			</div>;
		})}
	</>;
}

function Cell({ cell, bayNumber, row, tier, hasTemplate, templateCells, highlightCell, editingMode, combinedBays }) {
	const container = cell?.container;
	const showCell = container != null || !hasTemplate || templateCells[toCellCode(bayNumber, row, tier)] != null;

	if (!showCell) {
		return <div key={tier} className="cell" style={{ opacity: 0 }} />;
	}

	const highlighted = highlightCell == toCellCode(bayNumber, row, tier) || (combinedBays && highlightCell == toCellCode(bayNumber + 1, row, tier));
	const fortyGhost = cell != null && bayNumber > cell.bay && editingMode == null;
	const statusMsg = ('' + bayNumber).padStart(2, '0') + ('' + row).padStart(2, '0') + ('' + tier).padStart(2, '0');

	const is20only = bayNumber % 2 == 1 && templateCells[toCellCode(bayNumber + 1, row, tier)] == null;
	const is40 = bayNumber % 2 == 0 || (bayNumber % 2 == 1 && templateCells[toCellCode(bayNumber + 1, row, tier)] != null);
	const hasReeferPlug = bayNumber % 2 == 1 && (templateCells[toCellCode(bayNumber, row, tier)]?.hasReeferPlug || templateCells[toCellCode(bayNumber + 1, row, tier)]?.hasReeferPlug);

	return <div
		key={tier}
		className={"cell" + (fortyGhost ? ' cell-forty-ghost' : '') + (highlighted ? ' cell-highlighted' : '')}
		title={statusMsg}>
		<div className="cell-content" style={{ width: '100%', height: '100%', lineHeight: '1em', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundPosition: 'center', background: is20only ? 'var(--col-orange-300)' : 'var(--col-green-300)' }}>
			{hasReeferPlug && <span className="fa fa-snowflake-o" style={{ color: '#00f', fontSize: '6pt', height: '100%' }} />}
		</div>
	</div>;
}