import _ from 'lodash';
import { useDrag, useDrop } from 'react-dnd';
import React, { useContext } from 'react';
import { prepContainerNumber } from '../CreateContainerModal';
import PortColorFunction from '../../../../context/PortColorFunction';
import { calcDiggingColor, calcNumberBlockingContainers, calculateStylesForContainer } from './MiniBay';
import { useBayExclusionSets, useContainerExclusionSetsWithinBay, useExclusionCountForCell, useExclusionSetForCell } from '../digging/DiggingCalculationsProvider';

export default function BigBay({ bay, ...props }) {
	const { cells } = bay;

	const byRow = _.groupBy(cells, 'row');

	return <div className="bay bay-large">
		<div className="bay-upper-deck">
			<Rows
				{...props}
				bay={bay}
				lowerDeck={false}
				byRow={byRow} />
		</div>
		<div className="bay-lower-deck">
			<Rows
				bay={bay}
				lowerDeck={true}
				byRow={byRow}
				{...props} />
		</div>
	</div>;
}

function Rows({ containerDiggingMap, voyageData, colorMode, viewPart = 'ALL', showSwapSuggestions, cellsByCode, combinedBays, shifters, setHighlightCell, highlightCell, hasTemplate, templateCells, inOutBound, bay, bayNumber, lowerDeck = false, byRow, setStatusMsg, swapOutboundCells, polFilter, blockedCells, portUnlo, onToggleVisibility, editingMode, removeNext2Cells, addAnother2Cells, addCell, realisticContainerHeights, dontShowNumbersOfEmptiesToBeLoaded }) {
	return <>
		<TierNumbers hasTemplate={hasTemplate} bay={bay} lowerDeck={lowerDeck} editingMode={editingMode} />
		<div style={{ display: 'flex', flexDirection: 'row' }}>
			{generateRows(hasTemplate, bay, editingMode, viewPart).map((row, rIdx) => {
				const byTier = _.keyBy(byRow[row], 'tier');
				return <div key={row + ''} className="row" style={{ display: 'flex', flexDirection: 'column-reverse' }}>
					<div className="rowtier-label">
						{(row + '').padStart(2, '0')}
					</div>
					<div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
						{(lowerDeck ? generateLowerTiers(hasTemplate, bay, editingMode) : generateUpperTiers(hasTemplate, bay, editingMode)).map((tier, tIdx) => {
							const cell = byTier[tier];
							const container = cell?.container;
							const shipTemplateId = hasTemplate.id || null;
							const showCell = container != null || !hasTemplate || templateCells[toCellCode(bayNumber, row, tier)] != null;
							const checkNextCells = (bayNumber % 2 != 0 && templateCells[toCellCode(bayNumber + 1, row, tier)] != null && templateCells[toCellCode(bayNumber + 2, row, tier)] != null || bayNumber % 2 == 0 && templateCells[toCellCode(bayNumber - 1, row, tier)] != null && templateCells[toCellCode(bayNumber + 1, row, tier)] != null);
							const checkEvenNextCell = bayNumber % 2 == 0 && templateCells[toCellCode(bayNumber + 1, row, tier)] != null;
							const checkEvenPreviousCell = bayNumber % 2 == 0 && templateCells[toCellCode(bayNumber - 1, row, tier)] != null;

							let onClick;
							if (!showCell) {
								if (editingMode) {
									return <div onClick={() => addCell(bayNumber, row, tier)} key={tier} className={"cell" + (' cell-add')} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
										<span style={{ fontSize: 'var(--fs-16)' }}>&#43;</span>
									</div>;
								}
								return <div key={tier} className="cell" style={{ opacity: 0 }} />;
							} else{
								if (editingMode) {
									if (checkNextCells) {
										onClick = () => removeNext2Cells(bayNumber, row, tier);
									} else if ((!checkEvenNextCell && checkEvenPreviousCell) || (checkEvenNextCell && !checkEvenPreviousCell)) {
										onClick = () => addCell(bayNumber + 1, row, tier);
									} else {
										onClick = () => addAnother2Cells(bayNumber, row, tier);
									}
									return <div onClick={onClick} key={tier} className="cell" />;
								}
							}

							const isHighlighted = highlightCell == toCellCode(bayNumber, row, tier) || (combinedBays && highlightCell == toCellCode(bayNumber + 1, row, tier));

							return <Cell
								voyageData={voyageData}
								colorMode={colorMode}
								realisticContainerHeights={realisticContainerHeights}
								showSwapSuggestions={showSwapSuggestions}
								key={tier + ''}
								onToggleVisibility={onToggleVisibility}
								shifters={shifters}
								isHighlighted={isHighlighted}
								highlightCell={getHighlightedCell(cellsByCode, highlightCell, combinedBays)}
								setHighlightCell={setHighlightCell}
								templateCell={templateCells[toCellCode(bayNumber, row, tier)]}
								inOutBound={inOutBound}
								swapOutboundCells={swapOutboundCells}
								bayNumber={bayNumber}
								row={row}
								tier={tier}
								cell={cell}
								container={container}
								setStatusMsg={setStatusMsg}
								polFilter={polFilter}
								blockedCells={blockedCells}
								portUnlo={portUnlo}
								editingMode={editingMode}
								containerDiggingMap={containerDiggingMap}
								dontShowNumbersOfEmptiesToBeLoaded={dontShowNumbersOfEmptiesToBeLoaded} />;
						})}
					</div>
					<div style={{ flex: 1 }} />
					<div className="rowtier-label print-only">
						{(row + '').padStart(2, '0')}
					</div>
				</div>;
			})}
		</div>
	</>;
}

function TierNumbers({ hasTemplate, bay, lowerDeck, editingMode }) {
	return <div style={{ minWidth: 'var(--u-24)' }}>
		<div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
			<div className="rowtier-label">&nbsp;</div>
			{(lowerDeck ? generateLowerTiers(hasTemplate, bay, editingMode) : generateUpperTiers(hasTemplate, bay, editingMode)).map((tier, tIdx) => {
				return <div key={tIdx} className="rowtier-label cell-height" style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
					{(tier + '').padStart(2, '0')}
				</div>;
			})}
		</div>
	</div>;
}

function Cell({ containerDiggingMap, voyageData, colorMode, realisticContainerHeights, showSwapSuggestions, onToggleVisibility, shifters = new Set(), setHighlightCell, isHighlighted, highlightCell, templateCell, inOutBound, bayNumber, swapOutboundCells, row, tier, cell, container, setStatusMsg, polFilter, blockedCells, portUnlo, editingMode, dontShowNumbersOfEmptiesToBeLoaded }) {
	const unloToHue = useContext(PortColorFunction);

	const fortyGhost = cell != null && bayNumber > cell.bay && editingMode == null;
	const isShifter = shifters.has(prepContainerNumber(container?.equipmentIdentifier || ''));
	const isFaded = !isShifter && !(polFilter == null || (inOutBound == 'INBOUND' ? container?.portOfDischarge : container?.portOfLoading) == polFilter);
	let statusMsg = ('' + bayNumber).padStart(3, '0') + ('' + row).padStart(2, '0') + ('' + tier).padStart(2, '0');
	if (container) {
		statusMsg += ' - ' + (container.equipmentIdentifier || 'Unknown container');
		statusMsg += ' - ' + (container.isoCode);
		statusMsg += ' - ' + (container.portOfLoading);
		statusMsg += ' - ' + (container.portOfDischarge);
	} else {
		statusMsg += ' - Not assigned';
	}

	const [ { dragging, droppedItem }, connectDragRef, dragPreview ] = useDrag(
		() => ({
			type: 'CONTAINER',
			item: {
				id: container?.id,
				bayNumber: cell?.bay,
				row,
				tier,
				container: container,
			},
			end: (draggedItem, monitor) => {
				if (monitor.didDrop()) {
					const result = monitor.getDropResult();
					const cellCodeFrom = toCellCode(draggedItem.bayNumber, draggedItem.row, draggedItem.tier);
					const cellCodeTo = toCellCode(draggedItem.bayNumber % 2 == 0 && result.bayNumber % 2 != 0 ? (result.bayNumber + 1) : result.bayNumber, result.row, result.tier);
					swapOutboundCells(cellCodeFrom, cellCodeTo);
				}
			},
			canDrag: () => inOutBound == 'OUTBOUND' && swapOutboundCells != null && container != null && !fortyGhost,
			collect: (monitor) => ({
				dragging: monitor.isDragging(),
				droppedItem: monitor.didDrop(),
			}),
		}),
		[ container, swapOutboundCells ]
	);

	const [ { hoverContainer }, connectDropRef ] = useDrop(() => ({
		accept: 'CONTAINER',
		drop: (item, monitor) => {
			return { bayNumber, row, tier };
		},
		canDrop: (item, monitor) => {
			// Allow 20-20ft swaps, and 40-40ft, but not 20-40ft or 40-20ft
			return cell == null || cell.bay % 2 == item.bayNumber % 2;
		},
		collect: monitor => ({
			hoverContainer: (monitor.isOver() && monitor.canDrop()) ? monitor.getItem()?.container : null,
		}),
	}), [ container, cell, bayNumber, row, tier ]);

	const displayCtr = hoverContainer ?? container;
	const higherThan2 = displayCtr != null && displayCtr?.isoCode != null && displayCtr?.isoCode?.slice(1, 2) > 2;
	const isLetter = displayCtr != null && displayCtr?.isoCode != null && (/[a-z]/i).test(displayCtr?.isoCode?.slice(1, 2));
	const isHighCube = displayCtr != null && (higherThan2 || isLetter);

	const wt = (Math.round((displayCtr?.grossWeight ?? 0) / 100) / 10).toFixed(1) || '';

	const imoCodes = [
		...new Set(
			(displayCtr?.dangerousGoods ?? [])
				.filter(dg => dg != null && dg.imoClass != null && dg.imoClass != '')
				.map(dg => dg.imoClass)
		),
	].sort().join(',');

	const { score: matchingScore } = containersCanBeSwapped(highlightCell?.container, displayCtr);

	const displayVoyageData = inOutBound == 'INBOUND' ? (voyageData?.inbound ?? {})[displayCtr?.equipmentIdentifier] : (voyageData?.outbound ?? {})[displayCtr?.equipmentIdentifier];
	const exclusionSet = useExclusionSetForCell(cell);
	const exclusionSetHighlightCell = useExclusionSetForCell(highlightCell);
	const cellColor = calculateStylesForContainer(displayCtr, inOutBound, voyageData, colorMode, isFaded, unloToHue, containerDiggingMap, exclusionSet, false);

	const containerNumberToDisplay = dontShowNumbersOfEmptiesToBeLoaded && displayCtr?.isEmpty && inOutBound == 'OUTBOUND' ? '' : (displayCtr?.equipmentIdentifier || '');
	const hasDiggingInfo = container != null && container.equipmentIdentifier != null && container.equipmentIdentifier != '' && containerDiggingMap[container.equipmentIdentifier] != null;
	const yardLocation = (displayVoyageData != null && displayVoyageData?.yardLocation != null ? displayVoyageData?.yardLocation : (inOutBound == 'OUTBOUND' ? (displayCtr?.stackLocation || '-') : '')) || (displayCtr?.stackLocation ?? '');

	const exclusionCount = useExclusionCountForCell(cell);

	return <div
		ref={(divRef) => {
			connectDragRef(divRef);
			connectDropRef(divRef);
		}}
		className={"cell" + (fortyGhost ? ' cell-forty-ghost' : '') + (dragging ? ' cell-dragging' : '') + (isHighlighted ? ' cell-highlighted' : '') + (isHighCube && realisticContainerHeights ? ' cell-highcube' : '')}
		onMouseOver={() => setStatusMsg && setStatusMsg(statusMsg)}
		onDoubleClick={() => onToggleVisibility(container?.id, (isShifter || container?.visibility == 'VISIBLE') ? 'AUTO' : 'VISIBLE')}
		onClick={() => setTimeout(() => (isHighlighted ? setHighlightCell(null) : setHighlightCell(toCellCode(cell?.bay || bayNumber, row, tier))), 0)}>
		{(showSwapSuggestions && highlightCell != null && displayCtr != null) && <div style={{ zIndex: 10, position: 'absolute', inset: 0, background: 'rgba(0, 0, 0, ' + ((1 - matchingScore) * 0.5) + ')' }} />}
		{showSwapSuggestions && (containerDiggingMap[highlightCell?.container?.equipmentIdentifier]?.blocking ?? []).includes(displayCtr?.equipmentIdentifier) && <div style={{ zIndex: 10, position: 'absolute', inset: 0, border: exclusionSetHighlightCell.has(displayCtr?.equipmentIdentifier) ? '3px solid rgba(0, 100, 150, 0.8)' : '3px solid rgba(200, 20, 20, 0.7)' }} />}
		<div
			ref={dragPreview}
			className="cell-content"
			style={{ width: '100%', height: '100%', background: cellColor }}>
			{blockedCells != null && blockedCells.has(toCellCode(bayNumber, row, tier)) && <div className="cell-blocked" />}
			{displayCtr && !isFaded && <div style={{ fontSize: '7.4pt', lineHeight: '1.1em', height: '100%' }}>
				<div className="cell-oversize-indicators">
					{displayCtr.overHeight != null && <div className="cell-oversize-height" style={{ display: 'block' }} />}
					{displayCtr.overWidthLeft != null && <div className="cell-oversize-width-left" style={{ display: 'block' }} />}
					{displayCtr.overWidthRight != null && <div className="cell-oversize-width-right" style={{ display: 'block' }} />}
				</div>

				{(displayCtr?.stackLocation == '*' || displayCtr?.carrierReference == '*') && <div className="user-highlight-indicator" />}
				{(displayCtr.attachedEquipment[0] != '' && displayCtr.attachedEquipment.length > 0) && <div className="flatrack-indicator" />}
				{displayCtr.reeferTemperature != null && <div className="reefer-indicator" />}
				{displayCtr.dangerousGoods.length > 0 && <div className="dgs-indicator" />}
				{isHighCube && <div className='highcube-indicator' />}

				<div style={{ height: '100%', display: 'grid', gridTemplateRows: '1fr 1fr 1fr', zIndex: 5, position: 'relative' }}>
					<div style={{ position: 'absolute', top: 0, right: 0 }}>{displayCtr.isEmpty ? 'L' : 'V'}</div>
					<div style={{ textAlign: 'center' }}>
						 {hasDiggingInfo
							? <div className="pill pill-small" style={{ background: calcDiggingColor(container, containerDiggingMap, exclusionSet, 0.75), borderColor: calcDiggingColor(container, containerDiggingMap, exclusionSet, 0.3), color: calcDiggingColor(container, containerDiggingMap, exclusionSet, 0.05), fontWeight: 'bold' }}>
								{calcNumberBlockingContainers(container, containerDiggingMap, exclusionSet)}
								<div style={{ display: 'inline-block', width: '1px', height: '1em', borderLeft: '1px solid ' + calcDiggingColor(container, containerDiggingMap, exclusionSet, 0.3), margin: '0 var(--u-1) 0 var(--u-2)' }} /><span style={{ fontWeight: 'normal' }}>{yardLocation}</span>
							</div>
							: <div>{yardLocation}</div>}
					</div>
					<div style={{ textAlign: 'center', fontWeight: 'bold', margin: '0 -2px', letterSpacing: '-0.2px' }}>{prepContainerNumber(containerNumberToDisplay).slice(0, 4)}<span style={{ display: 'inline-block', width: '0.8px' }} />{prepContainerNumber(containerNumberToDisplay).slice(4)}</div>
					<div style={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr' }}>
						<div style={{ textAlign: 'left' }}>
							<span title={imoCodes}>{imoCodes.slice(0, 5)}{imoCodes.length > 5 ? '...' : ''}</span>
							{(displayCtr.attachedEquipment[0] != '' && displayCtr.attachedEquipment.length > 0) && <span>{displayCtr.attachedEquipment.length} <span style={{ fontSize: '13px' }}>&times;</span></span>}
						</div>
						<div style={{ textAlign: 'center', letterSpacing: '-0.5px' }}>
							{(parseFloat(wt) || 0).toFixed(1).split('.')[0]}.<span style={{ fontSize: '0.9em' }}>{(parseFloat(wt) || 0).toFixed(1).split('.')[1]}</span>
						</div>
						<div style={{ textAlign: 'right', letterSpacing: '-0.3px' }}>{displayCtr.isoCode}</div>
					</div>
				</div>
			</div>}
			{!displayCtr && <div style={{ height: '100%', display: 'grid', gridTemplateRows: '1fr 1fr 1fr', zIndex: 5, position: 'relative' }}>
				<div />
				<div />
				<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
					<div />
					<div />
					<div style={{ textAlign: 'center', fontSize: '7pt' }}>
						{templateCell?.hasReeferPlug && <div style={{ color: 'var(--col-grey-300)' }}><span className="fa fa-snowflake-o" /></div>}
					</div>
				</div>
			</div>}
		</div>
	</div>;
}

// Generate upper deck
export function generateUpperTiers(hasTemplate, bay, editingMode) {
	if (!hasTemplate || editingMode) {
		return [ 82, 84, 86, 88, 90, 92, 94, 96, 98 ];
	} else {
		const minTier = Math.min(...[ ...bay.tiers ].filter(t => t >= 70));
		const maxTier = Math.max(...[ ...bay.tiers ].filter(t => t >= 70));
		const tiers = [];
		for (let i = minTier; i <= maxTier; i += 2) {
			tiers.push(i);
		}
		return tiers;
	}
}

export function generateLowerTiers(hasTemplate, bay, editingMode) {
	if (!hasTemplate || editingMode) {
		return [ 2, 4, 6, 8, 10, 12, 14, 16, 18, 20 ];
	} else {
		const minTier = Math.min(...[ ...bay.tiers ].filter(t => t < 70));
		const maxTier = Math.max(...[ ...bay.tiers ].filter(t => t < 70));
		const tiers = [];
		for (let i = minTier; i <= maxTier; i += 2) {
			tiers.push(i);
		}
		return tiers;
	}
}

// Generate rows based on row array
export function generateRows(hasTemplate, bay, editingMode, viewPart = 'ALL') {
	if (!hasTemplate || editingMode) {
		return [ 14, 12, 10, 8, 6, 4, 2, 0, 1, 3, 5, 7, 9, 11, 13 ];
	} else {
		const highestEven = Math.max(...[ ...bay.rows ].filter(r => r % 2 == 0));
		const highestUneven = Math.max(...[ ...bay.rows ].filter(r => r % 2 == 1));
		const rows = [];
		if (viewPart == 'ALL' || viewPart == 'LEFT') {
			for (let i = highestEven; i > 0; i -= 2) {
				rows.push(i);
			}
			if (bay.rows.has(0)) rows.push(0);
		}
		if (viewPart == 'ALL' || viewPart == 'RIGHT') {
			for (let i = 1; i <= highestUneven; i += 2) {
				rows.push(i);
			}
		}
		return rows;
	}
}

export function toCellCode(bay, row, tier) {
	return (
		('' + bay).padStart(3, '0')
		+ ('' + row).padStart(2, '0')
		+ ('' + tier).padStart(2, '0')
	);
}

export function getHighlightedCell(cellsByCode, highlightCell, combinedBays) {
	if (highlightCell == null) return null;
	if (cellsByCode[highlightCell]) return cellsByCode[highlightCell];

	const hBayNr = parseInt(highlightCell.slice(0, 3), 10) + 1;
	const hRowNr = parseInt(highlightCell.slice(3, 5), 10);
	const hTierNr = parseInt(highlightCell.slice(5, 7), 10);
	if (combinedBays && cellsByCode[toCellCode(hBayNr, hRowNr, hTierNr)]) {
		return cellsByCode[toCellCode(hBayNr, hRowNr, hTierNr)];
	}

	return null;
}

export function containersCanBeSwapped(a, b) {
	if (a == null || b == null) return { possible: false, score: 0 };
	if (a.portOfDischarge != b.portOfDischarge) return { possible: false, score: 0 };
	if (a.portOfLoading != b.portOfLoading) return { possible: false, score: 0 };
	if (a.isoCode == null || b.isoCode == null || a.isoCode[0] != b.isoCode[0]) return { possible: false, score: 0 };
	if (Math.abs(a.grossWeight - b.grossWeight) > 10000) return { possible: false, score: 0 };
	return { possible: true, score: 1 - (Math.max(Math.abs(a.grossWeight - b.grossWeight) - 2000, 0) / 8000) };
}