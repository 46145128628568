import _ from 'lodash';
import React from 'react';

export default function BigBayTemplate({ bay, ...props }) {
	const { cells } = bay;

	const byRow = _.groupBy(cells, 'row');

	return <div className="bay bay-large">
		<div className="bay-upper-deck">
			<Rows
				{...props}
				bay={bay}
				lowerDeck={false}
				byRow={byRow} />
		</div>
		<div className="bay-lower-deck">
			<Rows
				bay={bay}
				lowerDeck={true}
				byRow={byRow}
				{...props} />
		</div>
	</div>;
}

function Rows({ viewPart = 'ALL', hasTemplate, templateCells, bay, bayNumber, lowerDeck = false, byRow, editingMode, removeCell, setCell, brush, setBlockCoordinates, setBlockPreviewCoordinates, blockCoordinates, blockPreviewCoordinates }) {
	return <>
		<TierNumbers hasTemplate={hasTemplate} bay={bay} lowerDeck={lowerDeck} editingMode={editingMode} />
		<div style={{ display: 'flex', flexDirection: 'row' }}>
			{generateRows(hasTemplate, bay, editingMode, viewPart).map((row, rIdx) => {
				const byTier = _.keyBy(byRow[row], 'tier');
				return <div key={row + ''} className="row" style={{ display: 'flex', flexDirection: 'column-reverse' }}>
					<div className="rowtier-label">
						{(row + '').padStart(2, '0')}
					</div>
					<div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
						{(lowerDeck ? generateLowerTiers(hasTemplate, bay, editingMode) : generateUpperTiers(hasTemplate, bay, editingMode)).map((tier, tIdx) => {
							const cell = byTier[tier];
							const container = cell?.container;
							const showCell = container != null || !hasTemplate || templateCells[toCellCode(bayNumber, row, tier)] != null;

							const is20only = bayNumber % 2 == 1 && templateCells[toCellCode(bayNumber + 1, row, tier)] == null;
							const is40 = bayNumber % 2 == 0 || (bayNumber % 2 == 1 && templateCells[toCellCode(bayNumber + 1, row, tier)] != null);
							const hasReeferPlug = bayNumber % 2 == 1 && (templateCells[toCellCode(bayNumber, row, tier)]?.hasReeferPlug || templateCells[toCellCode(bayNumber + 1, row, tier)]?.hasReeferPlug);

							const blockCells = [];
							let currentCellInBlockPreview = false;

							if (blockCoordinates != null && blockPreviewCoordinates != null) {
								const rows = generateRows(hasTemplate, bay, editingMode, viewPart);
								if (blockPreviewCoordinates.tier >= 70 == blockCoordinates.tier >= 70) {
									const rowIdxStart = rows.findIndex(r => r == blockCoordinates.row);
									const rowIdxEnd = rows.findIndex(r => r == blockPreviewCoordinates.row);

									for (let r = Math.min(rowIdxStart, rowIdxEnd); r <= Math.max(rowIdxStart, rowIdxEnd); r++) {
										const brow = rows[r];
										for (let t = Math.min(blockPreviewCoordinates.tier, blockCoordinates.tier); t <= Math.max(blockPreviewCoordinates.tier, blockCoordinates.tier); t++) {
											if (bayNumber == blockCoordinates.bayNumber && row == brow && t == tier) {
												currentCellInBlockPreview = true;
											}
											blockCells.push({ bayNumber: blockCoordinates.bayNumber, row: brow, tier: t });
										}
									}
								}
							}

							return <div
								key={tier}
								className={"cell" + (showCell ? '' : ' cell-add') + (currentCellInBlockPreview ? ' cell-block-preview' : '')}
								style={{ cursor: 'pointer', userSelect: 'none' }}
								onMouseDown={() => setBlockCoordinates({ bayNumber, row, tier })}
								onMouseOver={() => setBlockPreviewCoordinates({ bayNumber, row, tier })}
								onMouseUp={() => {
									const cells = blockCells;
									setBlockCoordinates(null);
									if (cells.length == 0) {
										cells.push({ bayNumber, row, tier });
									}

									if (brush.size == 'REMOVE') {
										cells.forEach(({ bayNumber, row, tier }) => {
											removeCell(bayNumber, row, tier);
											if (!is20only) {
												removeCell(bayNumber + 1, row, tier);
												removeCell(bayNumber + 2, row, tier);
											}
										});
									} else if (brush.size == '20') {
										cells.forEach(({ bayNumber, row, tier }) => {
											setCell(bayNumber, row, tier, brush.reeferPlug);
											removeCell(bayNumber + 1, row, tier);
										});
									} else {
										cells.forEach(({ bayNumber, row, tier }) => {
											setCell(bayNumber, row, tier, brush.reeferPlug);
											setCell(bayNumber + 1, row, tier, brush.reeferPlug);
											setCell(bayNumber + 2, row, tier, brush.reeferPlug);
										});
									}
								}}>
								{!showCell && <span style={{ fontSize: 'var(--fs-16)' }}>&#43;</span>}
								{showCell && <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 'var(--u-2)', fontSize: '8pt', lineHeight: '16px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>
									<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 'var(--u-2)' }}>
										<div style={{ width: '16px', background: 'var(--col-orange-500)', borderRadius: '2px' }}>20</div>
										{is40 && <div style={{ width: '16px', background: 'var(--col-green-500)', borderRadius: '2px' }}>40</div>}
									</div>
									<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 'var(--u-2)' }}>
										{hasReeferPlug && <div style={{ width: '16px', background: 'var(--col-primary-400)', borderRadius: '2px' }}><span className="fa fa-snowflake-o" /></div>}
									</div>
								</div>}
							</div>;
							// }
						})}
					</div>
					<div style={{ flex: 1 }} />
					<div className="rowtier-label print-only">
						{(row + '').padStart(2, '0')}
					</div>
				</div>;
			})}
		</div>
	</>;
}

function TierNumbers({ hasTemplate, bay, lowerDeck, editingMode }) {
	return <div style={{ minWidth: 'var(--u-24)' }}>
		<div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
			<div className="rowtier-label">&nbsp;</div>
			{(lowerDeck ? generateLowerTiers(hasTemplate, bay, editingMode) : generateUpperTiers(hasTemplate, bay, editingMode)).map((tier, tIdx) => {
				return <div key={tIdx} className="rowtier-label cell-height" style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
					{(tier + '').padStart(2, '0')}
				</div>;
			})}
		</div>
	</div>;
}

// Generate upper deck
export function generateUpperTiers(hasTemplate, bay, editingMode) {
	if (!hasTemplate || editingMode) {
		return [ 82, 84, 86, 88, 90, 92, 94, 96, 98 ];
	} else {
		const minTier = Math.min(...[ ...bay.tiers ].filter(t => t >= 70));
		const maxTier = Math.max(...[ ...bay.tiers ].filter(t => t >= 70));
		const tiers = [];
		for (let i = minTier; i <= maxTier; i += 2) {
			tiers.push(i);
		}
		return tiers;
	}
}

export function generateLowerTiers(hasTemplate, bay, editingMode) {
	if (!hasTemplate || editingMode) {
		return [ 2, 4, 6, 8, 10, 12, 14, 16, 18, 20 ];
	} else {
		const minTier = Math.min(...[ ...bay.tiers ].filter(t => t < 70));
		const maxTier = Math.max(...[ ...bay.tiers ].filter(t => t < 70));
		const tiers = [];
		for (let i = minTier; i <= maxTier; i += 2) {
			tiers.push(i);
		}
		return tiers;
	}
}

// Generate rows based on row array
export function generateRows(hasTemplate, bay, editingMode, viewPart = 'ALL') {
	if (!hasTemplate || editingMode) {
		return [ 14, 12, 10, 8, 6, 4, 2, 0, 1, 3, 5, 7, 9, 11, 13 ];
	} else {
		const highestEven = Math.max(...[ ...bay.rows ].filter(r => r % 2 == 0));
		const highestUneven = Math.max(...[ ...bay.rows ].filter(r => r % 2 == 1));
		const rows = [];
		if (viewPart == 'ALL' || viewPart == 'LEFT') {
			for (let i = highestEven; i > 0; i -= 2) {
				rows.push(i);
			}
			if (bay.rows.has(0)) rows.push(0);
		}
		if (viewPart == 'ALL' || viewPart == 'RIGHT') {
			for (let i = 1; i <= highestUneven; i += 2) {
				rows.push(i);
			}
		}
		return rows;
	}
}

export function toCellCode(bay, row, tier) {
	return (
		('' + bay).padStart(3, '0')
		+ ('' + row).padStart(2, '0')
		+ ('' + tier).padStart(2, '0')
	);
}

export function getHighlightedCell(cellsByCode, highlightCell, combinedBays) {
	if (highlightCell == null) return null;
	if (cellsByCode[highlightCell]) return cellsByCode[highlightCell];

	const hBayNr = parseInt(highlightCell.slice(0, 3), 10) + 1;
	const hRowNr = parseInt(highlightCell.slice(3, 5), 10);
	const hTierNr = parseInt(highlightCell.slice(5, 7), 10);
	if (combinedBays && cellsByCode[toCellCode(hBayNr, hRowNr, hTierNr)]) {
		return cellsByCode[toCellCode(hBayNr, hRowNr, hTierNr)];
	}

	return null;
}

export function containersCanBeSwapped(a, b) {
	if (a == null || b == null) return { possible: false, score: 0 };
	if (a.portOfDischarge != b.portOfDischarge) return { possible: false, score: 0 };
	if (a.portOfLoading != b.portOfLoading) return { possible: false, score: 0 };
	if (a.isoCode == null || b.isoCode == null || a.isoCode[0] != b.isoCode[0]) return { possible: false, score: 0 };
	if (Math.abs(a.grossWeight - b.grossWeight) > 10000) return { possible: false, score: 0 };
	return { possible: true, score: 1 - (Math.max(Math.abs(a.grossWeight - b.grossWeight) - 2000, 0) / 8000) };
}